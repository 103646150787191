import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/tips.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">Didn't find an answer? Join the conversation over at our <a href="https://discord.gg/Pegasys">official Discord</a> to get direct support.</Info>
    <h1 {...{
      "id": "what-risks-are-involved-with-using-pegasys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#what-risks-are-involved-with-using-pegasys",
        "aria-label": "what risks are involved with using pegasys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What risks are involved with using Pegasys?`}</h1>
    <p>{`The risks of using Pegasys are nearly identical on any Automated Market Maker (AMM) platforms: impermanent loss, a potential smart contract bug and rug pulls are three of the most common risks associated with this sector of DeFi.`}</p>
    <p>{`As Pegasys is a fork of Uniswap and Pangolin, without a single line of core protocol being changed, just with a slight cosmetic makeover (UI), powered by the Syscoin Network for much faster and cheaper transactions, and Uniswap’s been professionally audited and hasn’t been hacked to date, the risks seem reasonable regarding the smart contract itself.`}</p>
    <p>{`If you want to dive into details check out the `}<a parentName="p" {...{
        "href": "https://uniswap.org/audit.html"
      }}>{`Uniswap audit`}</a>{` or the `}<a parentName="p" {...{
        "href": "https://github.com/pangolindex/exchange-contracts/tree/main/audits"
      }}>{`Pangolin audits`}</a>{`.`}</p>
    <p>{`Impermanent loss can occur when price fluctuations occur in an asset pool you’re providing liquidity to. However, profits gained from fees and yield farming can sometimes counter this, allowing LPs to still acquire a net positive, depending on timing, market conditions and other variables.`}</p>
    <p>{`Rug pulls happen when a coin gets hyped up and a whale suddenly pulls out of a pool after the price has been pumped, often artificially or through hype manipulation. Be vigilant against such games and certainly don’t initiate them.`}</p>
    <p>{`Take caution and do your own research before yield farming, consider any risks involved and your own risk tolerance, and be prepared to accept a loss in some situations. You might consider yield farming with a relatively low percentage of your crypto portfolio reserved for higher risk investments. Also feel free to reach out to our community on `}<a parentName="p" {...{
        "href": "https://discord.gg/Pegasys"
      }}>{`Discord`}</a>{` regarding upcoming projects you’re thinking about investing.`}</p>
    <h1 {...{
      "id": "how-do-i-move-tokens-between-the-ethereum-and-the-syscoin-network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-move-tokens-between-the-ethereum-and-the-syscoin-network",
        "aria-label": "how do i move tokens between the ethereum and the syscoin network permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I move tokens between the Ethereum and the Syscoin Network?`}</h1>
    <p>{`Guide for the Syscoin NEVM Mainnet is coming soon.`}</p>
    <h1 {...{
      "id": "how-do-i-farm-psys-on-pegasys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-farm-psys-on-pegasys",
        "aria-label": "how do i farm psys on pegasys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I farm PSYS on Pegasys?`}</h1>
    <p>{`On the Pegasys app of the site you’ll find the current pools listed which reward PSYS to liquidity providers and the daily amounts allocated to each pool. The PSYS you receive is directly proportional to the percentage of the pool your liquidity occupies and is calculated every block; about every 2.5 minutes.`}</p>
    <p>{`The standard flow is:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Provide Liquidity to the pool of your choice on the `}<a parentName="p" {...{
            "href": ""
          }}>{`Pool page`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Receive LP Tokens (also called “Pool Tokens”) in exchange for your deposit (think of it as a sort of receipt, i.e. a redeemable proof of deposit).`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Deposit your LP tokens to the corresponding pool on the REWARDS pools page.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`After you’ve confirmed all transactions on MetaMask, which it will automagically prompt you to do, that’s it! You’re now farming PSYS!`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can withdraw your liquidity at any time. If you wish to remove your tokens, or transfer your liquidity to a different pool, simply follow these same steps in reverse order.`}</p>
      </li>
    </ol>
    <h1 {...{
      "id": "how-much-do-i-receive-in-fees-for-providing-liquidity-on-pegasys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-much-do-i-receive-in-fees-for-providing-liquidity-on-pegasys",
        "aria-label": "how much do i receive in fees for providing liquidity on pegasys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How much do I receive in fees for providing liquidity on Pegasys?`}</h1>
    <p>{`There is an one-sided 0.3% fee for swaps performed on Pegasys. Liquidity Providers receive 0.25% of these fee’s for their share in the pool. Pegasys DAO receive 0.03% to decide on bruns, distributions or any other of community efforts governance votes on, and the final 0.02% goes to the Pegasys team.`}</p>
    <h1 {...{
      "id": "how-can-i-determine-which-pool-has-the-best-annual-percentage-yield-apy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-can-i-determine-which-pool-has-the-best-annual-percentage-yield-apy",
        "aria-label": "how can i determine which pool has the best annual percentage yield apy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I determine which pool has the best Annual Percentage Yield (APY)?`}</h1>
    <p>{`After depositing your LP tokens, the pool you’re contributing to will darken in color. In the lower right of that tab it will tell you how many PSYS you’re currently receiving per day, calculated by what percentage of the pool your stake is in relation to the PSYS/day allocated to that pool.`}</p>
    <p>{`To learn how to calculate APY, see the next question/answer.`}</p>
    <p>{`Additional Resource for Liquidity Pool Analytics:`}</p>
    <p><a parentName="p" {...{
        "href": "https://apy.vision/#/"
      }}>{`https://apy.vision/#/`}</a></p>
    <h1 {...{
      "id": "how-do-i-calculate-apy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-do-i-calculate-apy",
        "aria-label": "how do i calculate apy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How do I calculate APY?`}</h1>
    <p>{`Many DeFi AMMs have automatically calculated APY for you. Be aware that this can be highly misleading as there are so many variables that can fluctuate over the course of a year. However, if you can’t wait for this to be automated on Pegasys, and would like to calculate the APY manually, you can do so according to these instructions:`}</p>
    <p>{`Annual percentage yield (APY) is the percentage of growth, or “rate of return”, you earn on an investment over a year with compounding interest. As you earn PSYS, you can reinvest it and add to the principal amount.`}</p>
    <p>{`This is different than a simple interest rate, which simply pays interest without compounding it.
How frequently you do this will affect your APY. In traditional finance this is commonly done monthly or quarterly. With Pegasys, it’s not done automatically, but you can do it yourself as often as you like. This will cause your investment to grow exponentially through compounding interest. The elementary formula is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`APY = (1 + r/n)n — 1

R = the interest rate
`}</code></pre>
    <p>{`On Pegasys “R” would equal the current total dollar value of the tokens received from the pool you’ve provided liquidity to, for a year, divided by your initial total capital invested. This will of course vary, depending on the current percentage of the pool your LP tokens are in.`}</p>
    <p>{`For example, if I deposited $500 of PSYS and $500 of UNI and I’m earning 2 PSYS tokens per day, my initial investment is $1,000.`}</p>
    <p>{`I must now determine the current value of PSYS in USD in order to calculate R. Let’s say the current value of PSYS is $10 per token. I’m receiving $20/day multiplied by 365 days.`}</p>
    <pre><code parentName="pre" {...{}}>{`$20 x 365 = $7,300

$7,300/$1,000 = 7.3

R = 730%
`}</code></pre>
    <p>{`N is the number of times you plan to add your rewarded PSYS tokens to your current liquidity in a year, compounding your interest. Banks commonly compound interests either monthly or quarterly, while AMMs usually assume you’re compounding daily.`}</p>
    <p>{`From this point on you can use one of the many tools available out there to do the APR to APY conversion. We recommend `}<a parentName="p" {...{
        "href": "https://www.aprtoapy.com/"
      }}>{`aprtoapy.com/`}</a>{`.`}</p>
    <p>{`To be conservative. Let’s say you plan to do this once a week, which leads our final APY to be 92,512.62%.`}</p>
    <p>{`As you can imagine, in DeFi being so new with the volatility of token prices, the APY is going to change frequently. It’s contingent on who deposits or withdraws from the pool, how much, and the current price of the PSYS token. But the above method can provide you with a frame of reference by which you can monitor ongoing activity manually.`}</p>
    <h1 {...{
      "id": "how-is-the-amount-of-lp-tokens-i-get-from-a-pool-determined",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-is-the-amount-of-lp-tokens-i-get-from-a-pool-determined",
        "aria-label": "how is the amount of lp tokens i get from a pool determined permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How is the amount of LP tokens I get from a pool determined?`}</h1>
    <p>{`It can vary widely as it’s not only based on your percentage of each pool, but also on how much the first liquidity provider at the start of the campaign deposited.`}</p>
    <p>{`Uniswap has a decent explanation of this here: `}<a parentName="p" {...{
        "href": "https://uniswap.org/docs/v2/core-concepts/pools/"
      }}>{`https://uniswap.org/docs/v2/core-concepts/pools/`}</a></p>
    <h1 {...{
      "id": "how-can-i-find-out-how-much-traffic-is-occurring-in-each-pool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#how-can-i-find-out-how-much-traffic-is-occurring-in-each-pool",
        "aria-label": "how can i find out how much traffic is occurring in each pool permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How can I find out how much traffic is occurring in each pool?`}</h1>
    <p>{`You can find this and other info on our analytics page.`}</p>
    <h1 {...{
      "id": "is-psys-going-to-be-listed-on-any-cexs-in-the-future-or-will-it-only-be-available-through-staking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#is-psys-going-to-be-listed-on-any-cexs-in-the-future-or-will-it-only-be-available-through-staking",
        "aria-label": "is psys going to be listed on any cexs in the future or will it only be available through staking permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Is PSYS going to be listed on any CEXs in the future? Or will it only be available through staking?`}</h1>
    <p>{`Exchange listing for any crypto project is contingent on a large number of factors. It’s impossible to predict an exact date, but we’re optimistic that as the user base grows, and the team works hard, we will be listed on a mainline CEX.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      